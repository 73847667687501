
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class TagCodeSingle extends Vue {
  // Props
  @Prop()
  trackingCode!: string

  @Prop()
  tagJsUrl!: string

  get tagCode(): string {
    /* eslint-disable no-useless-escape */
    return `
    <script async type="text/javascript" src="//${this.tagJsUrl}"><\/script>
    `
  }

  tagCopySuccess(): void {
    this.$q.notify({
      message: this.$t('label.copied'),
      color: 'positive',
    })
  }

  downloadTagCode() {
    const html = this.$t('label.comment_txt_file_download_tag') + this.tagCode
    const blob = new Blob([html], { type: 'text/plain' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'CV tag.txt'
    link.click()
  }

  copy(): void {
    const myFluffyTextarea = document.createElement('textarea')

    myFluffyTextarea.innerHTML = this.tagCode

    const parentElement = document.getElementById('tabcode')
    if (!parentElement) {
      return
    }

    parentElement.appendChild(myFluffyTextarea)
    myFluffyTextarea.select()

    document.execCommand('copy')

    parentElement.removeChild(myFluffyTextarea)
    this.tagCopySuccess()
  }
}
