import { render } from "./TagCodeSingle.vue?vue&type=template&id=11522100&scoped=true"
import script from "./TagCodeSingle.vue?vue&type=script&lang=ts"
export * from "./TagCodeSingle.vue?vue&type=script&lang=ts"

import "./TagCodeSingle.vue?vue&type=style&index=0&id=11522100&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-11522100"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon});
